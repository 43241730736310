import { SelectItem } from '../../../../dsl/atoms/Select'
import { ConsentProps } from '../../molecules/Consents/Consents.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const CONTACT_FORM_COMPONENT_KEY = 'ContactFormComponent'

export interface ContactFormConfig {
  consents: ConsentProps[]
  subjects: SelectItem[]
  showRodo: boolean
  input: {
    animatedLabel: boolean
  }
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const defaultComponentConfig: ContactFormConfig = {
  consents: [],
  subjects: [],
  showRodo: true,
  input: {
    animatedLabel: true
  }
}
